


//Slider_home

$(document).ready(function () {
    $('.player-home').slick({
        arrows: false,
        adaptiveHeight: true,
    })
});



//Trocar SVG por PNG

if (!Modernizr.svg) {
    $('img[src*="svg"]').attr('src', function () {
        return $(this).attr('src').replace('.svg', '.png');
    });
}

// Menu mobile
$( ".click-menu" ).click(function() {
  $("body").toggleClass( "show-menu" );
});
$( ".button-close" ).click(function() {
  $("body").removeClass( "show-menu" );
});
